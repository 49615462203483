@import "../../styles/constants.scss";

.testimonial-cards-container {
  overflow-x: scroll;
  padding: 32px 0px;
  text-align: center;
  white-space: nowrap;

  // From https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
  // To hide non-webkit scrollbars
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    // Hide scrollbar for webkit browsers
    width: 0px;
    height: 0px;
  }

  .testimonial-card-wrapper {
    display: inline-block;
    margin: 16px;
    text-align: left;
    vertical-align: top;
    white-space: normal;
  }

  @media (max-width: $mobile-breakpoint) {
    &.reverse {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
