@import "../../styles/constants.scss";

.marketing-banner-wrapper {
  width: 100%;
  display: flex;
  padding-top: 75px;
  padding-bottom: 65px;
  // Default center placement
  // With no background image
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &.no-bottom-padding {
    // Based on props, remove bottom padding
    padding-bottom: 0px;
  }

  &.right {
    justify-content: flex-end;
    padding-left: $desktop-side-padding;
  }

  &.left {
    justify-content: flex-start;
    padding-right: $desktop-side-padding;
  }

  &.white {
    background-color: $color-white;
  }

  &.off-white {
    background-color: $color-lightest-grey;
  }
}

// Mobile differences
@media (max-width: $mobile-breakpoint) {
  .marketing-banner-wrapper {
    // Top and bottom padding
    padding: $mobile-top-bottom-padding 0px;
    background-size: auto 100%;

    &.mobile-no-bottom-padding {
      // Based on props, remove bottom padding
      padding-bottom: 0px;
    }

    &.mobile-center {
      // Overwrite default/desktop alignment if mobile alignment is 'center' and desktop isn't
      padding-left: 0px;
      padding-right: 0px;
    }

    &.mobile-right {
      padding-left: $mobile-side-padding;
      padding-right: 0px;

      // add padding to text but ignore other elements
      h4,
      p {
        padding-right: $mobile-side-padding;
      }
    }

    &.mobile-left {
      padding-right: $mobile-side-padding;
      padding-left: 0px;

      // add padding to text but ignore other elements
      h4,
      p {
        padding-left: $mobile-side-padding;
      }
    }

    &.mobile-off-white {
      background-color: $color-lightest-grey;
    }

    &.mobile-white {
      background-color: $color-white;
    }
  }
}

// Safety net to avoid uncovered edges
@media (min-width: calc($mobile-breakpoint)) and (max-width: calc($mobile-breakpoint + $mobile-breakpoint-adjustment)) {
  .marketing-banner-wrapper {
    background-size: 100% 100%;
  }
}
