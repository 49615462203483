@import "../../styles/constants.scss";

$slide-in-distance: 42px;

#accepted > .cookie-grid-container {
  transform: translateY(200%);
  transition: all 1s ease-in-out;
}

.cookie-grid-container {
  position: fixed;
  z-index: 5000;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: 0 45px 0 45px;

  animation: slide 1s forwards;
  animation-delay: 0.5s;

  @keyframes slide {
    100% {
      bottom: $slide-in-distance;
    }
  }

  @media screen and (max-width: $mobile-breakpoint-adjustment) {
    margin: 0;
  }

  .cookie-message-container {
    border-radius: $default-card-radius;
    background: $color-darkest-grey;
    padding: 45px 40px 45px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cookie-content {
      color: $color-white;
      font-size: $font-large-font-size;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
      }

      .description {
        margin-right: 100px;

        @media screen and (max-width: $mobile-breakpoint) {
          margin-right: 0;
        }
      }

      .links {
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: $mobile-breakpoint) {
          flex-direction: column;
          justify-content: center;
        }

        .accept-button {
          @include request-demo-button;
          max-height: 44px;
          white-space: nowrap;
          align-self: center;

          @media screen and (max-width: $mobile-breakpoint) {
            margin-top: 60px;
            margin-bottom: 30px;
            width: 100%;
          }

          @media screen and (max-width: $mobile-breakpoint-adjustment) {
            white-space: normal;
          }
        }

        .privacy-link {
          color: $color-pink;
          font-weight: $font-boldest-font-weight;
          align-self: center;
          margin-left: 40px;
          white-space: nowrap;

          @media screen and (max-width: $mobile-breakpoint) {
            margin-left: 0;
          }

          @media screen and (max-width: $mobile-breakpoint-adjustment) {
            white-space: normal;
          }

          .arrow {
            margin-left: 15px;
          }
        }
      }
    }

    .ignore-button {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 2px;

      @media screen and (max-width: $mobile-breakpoint-adjustment) {
        right: 30px;
      }
    }

    .cookie-header {
      color: $color-white;
      font-size: $font-xmlarge-font-size;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
}
