@import "../../styles/constants.scss";

$icon-animation-duration: 300ms;
$content-tab-height: 160px;

.mobile-nav-container {
  bottom: 0;
  font-weight: bold;
  margin-bottom: -$content-tab-height;
  position: fixed;
  text-align: center;
  transition: margin 500ms;
  touch-action: none;
  width: 100%;
  z-index: 100;

  .mobile-nav-menu-tab {
    color: $color-white;

    .tab-section {
      background: linear-gradient(180deg, #a664f3 15%, $color-purple 100%);
      display: inline-block;
      padding: 0.5rem 3rem 0rem;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      display: inline-block;
      cursor: pointer;

      .tab-section-icon {
        height: 20px;
        transform: rotate(180deg);
        transition: transform $icon-animation-duration;
      }

      .tab-section-icon.open {
        transform: rotate(360deg);
        transition: transform $icon-animation-duration;
      }
    }

    .menu-tab-header {
      background: $color-purple;
      padding: 10px 0px;
    }
  }

  .mobile-nav-content-tab {
    background-color: $color-white;
    height: $content-tab-height;
    padding: 0.5rem 10%; // use rem to match internal option spacing
    text-align: center;

    @media screen and (max-width: calc(
      $mobile-breakpoint - $mobile-breakpoint-adjustment
    )) {
      text-align: left;
    }

    .nav-content-option {
      cursor: pointer;
      padding: 0.5rem 0rem;
    }
  }
}

.open.mobile-nav-container {
  margin-bottom: 0px;
}
