@import "../../../styles/constants.scss";

.benefits-page {
  .marketing-dsp-banner-top-banner {
    width: 100vw;
  }

  .benefits-content-link {
    font-weight: bold;
    color: $color-pink;
  }
}
