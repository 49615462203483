@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800;900&display=swap');

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0 !important; // override material ui modifying this property on SwipeableDrawer open event
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
}
