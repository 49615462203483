@import "../../styles/constants.scss";

$h3-font-size: 32px;
$close-icon-width: 17px;

.form-success-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .form-success-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-white;
    color: $color-darkest-grey;
    padding: 40px;
    position: absolute;
    width: fit-content;
    text-align: center;
    border-radius: $default-card-radius;
    box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.16);

    img {
      width: $close-icon-width;
      height: $close-icon-width;
      margin: -16px -16px 13px 0px;
      align-self: flex-end;
      cursor: pointer;
    }

    h3 {
      margin: 0px;
      font-size: $h3-font-size;
      font-weight: $font-boldest-font-weight;
      line-height: 40px;
      padding: 0px 10px;
    }

    p {
      margin-top: 8px;
      margin-bottom: $h3-font-size;
      font-size: $font-large-font-size;
      font-weight: $font-default-font-weight;
      line-height: 32px;
    }

    .form-success-modal-loading-container {
      margin-top: 35px;
    }

    .form-success-modal-link {
      width: fit-content;
      height: fit-content;
    }
  }
}
