@import "../../styles/constants.scss";

$div-overlap-spacing: 50px;
$docs-right-side-spacing: 10%;

.integration-docs-wrapper {
  .integration-docs-header {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0% $docs-right-side-spacing $div-overlap-spacing 5%;

    h1 {
      color: $color-white;
      font-size: $mobile-h2-font-size;
      margin: 0rem;
      padding: 2rem 0rem 4rem;
    }
  }

  .integration-docs-content-wrapper {
    background-color: $color-lightest-grey;
    border-top-left-radius: 50px;
    margin-top: -$div-overlap-spacing;
    padding: 1rem $docs-right-side-spacing 5%;

    .integration-docs-content-header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .integration-lifecycle-markers-wrapper {
        display: inline-block;
        white-space: nowrap;

        .integration-lifecycle-marker {
          background-color: #dadadc;
          display: inline-block;
          height: 7px;
          margin: 0px 1px;
          width: 35px;
        }

        .integration-lifecycle-marker.selected {
          background-color: $color-pink;
          transition: background-color 500ms;
        }

        .integration-lifecycle-marker:nth-of-type(1) {
          border-radius: 20px 0px 0px 20px;
        }

        .integration-lifecycle-marker:last-child {
          border-radius: 0px 20px 20px 0px;
        }
      }

      .integration-step-btns-wrapper {
        white-space: nowrap;

        .integration-step-btn {
          background-color: $color-purple;
          border-radius: 50%;
          color: $color-white;
          cursor: pointer;
          display: inline-block;
          min-height: 35px;
          min-width: 35px;
          text-align: center;

          .integration-step-btn-icon {
            height: 20px;
            margin: 8px 13px;
            vertical-align: middle;
          }
        }

        .integration-step-btn.back-btn {
          margin: 0rem 1rem;
        }
      }
    }

    .integration-docs-content {
      @include integration-doc-content;

      .integration-docs-content-header {
        color: $color-purple;
        font-size: 22px;
      }

      .integration-step-bottom-nav {
        padding: 2.5rem 0rem 4rem;

        .integration-step-bottom-btn {
          display: inline-block;
          cursor: pointer;

          .next-btn-label {
            color: $color-pink;
            font-weight: bold;
            padding-right: 1rem;
          }

          .integration-step-btn-icon {
            vertical-align: middle;
          }
        }

        .integration-step-bottom-btn.back-btn {
          float: left;
        }

        .integration-step-bottom-btn.next-btn {
          float: right;
        }
      }
    }
  }
}
