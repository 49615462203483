@import "../../../styles/constants.scss";

.privacy-page-wrapper {
  background-color: $color-white;
  display: flex;
  flex-flow: column;

  .privacy-page-container {
    @include marketing-document-container;

    .privacy-page-header {
      padding-bottom: 20px;
    }

    ol,
    ul {
      list-style-position: outside;
      padding-left: 17px;

      li {
        font-weight: bold;
        padding: 5px 0px;
        margin-bottom: 1rem;
      }
    }

    p.privacy-links-header {
      margin-bottom: 5px;
    }

    ol.privacy-links {
      margin-top: 0;
      margin-left: 5px;

      li {
        color: $color-purple;
        margin-bottom: 0;
      }

      li:nth-child(1n + 10) {
        margin-left: 9px;
      }
    }

    ul.privacy-content {
      list-style-type: none;
      margin-left: -17px;

      li {
        margin-bottom: 0;
      }
    }

    ul.pi-usage-list {
      padding-left: 35px;

      @media screen and (max-width: calc(
        $mobile-breakpoint - $mobile-breakpoint-adjustment
      )) {
        padding-left: 10px;
      }

      li {
        font-weight: normal;
        padding-top: 0px;
        margin: 0;
      }

      margin-bottom: 20px;
    }

    table {
      width: 45%;
      margin-left: -17px;
      text-align: left;

      @media screen and (max-width: 1300px) {
        width: 60%;
      }

      @media screen and (max-width: 959px) {
        width: 70%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      td {
        font-size: $font-large-font-size;
        vertical-align: top;

        @media screen and (max-width: 768px) {
          font-size: $font-default-font-size;
        }
      }
    }
  }
}
