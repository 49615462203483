@import "../../styles/constants.scss";

$select-dark-grey: #120e17;
$select-label-vertical-padding: 8px;
$select-label-horizontal-padding: 14px;
$select-dropdown-max-height: 300px;
$hover-color: #f3f3f4;

.select-filter-dropdown {
  display: flex;
  align-items: center;
  position: relative;

  label.dropdown-label {
    font-weight: $font-boldest-font-weight;
    font-size: $font-xlarge-font-size;
    letter-spacing: -0.02em;
    margin-right: 10px;
    line-height: 24px;
  }

  .select-filter-select-container {
    color: $select-dark-grey;
    font-size: $font-large-font-size;

    .select-filter-select {
      background-color: $color-white;
      border: 1px solid #dbd9de;
      border-radius: $default-card-radius;
      cursor: pointer;
      display: inline-block;
      padding: $select-label-vertical-padding $select-label-horizontal-padding;

      // Run hover stylings only if enabled
      &:not(.disabled) {
        &.open,
        &:hover {
          background-color: $hover-color;
        }
      }

      &.disabled {
        cursor: default;
      }

      .select-filter-label {
        display: inline;
        padding-right: 1rem;
      }

      .select-filter-arrow {
        display: inline;
        pointer-events: none;

        &.hidden {
          visibility: hidden;
        }

        svg {
          stroke: rgba($select-dark-grey, 0.5);
          stroke-width: 2;
        }
      }
    }

    .select-filter-dropdown-list {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      background-color: $color-white;
      z-index: 8;
      padding: $select-label-vertical-padding 0px;
      border-radius: $default-card-radius;
      border: 1px solid #dbd9de;
      // Keep the dropdown from being insanely tall
      max-height: $select-dropdown-max-height;
      overflow-y: auto;
      overflow-x: hidden;

      span {
        padding: $select-label-vertical-padding
          calc($select-label-horizontal-padding + 8px)
          $select-label-vertical-padding $select-label-horizontal-padding;
        white-space: nowrap;

        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }
}
