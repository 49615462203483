@import "../../styles/constants.scss";

$textbox-height: 44px;
$textbox-font-size: 16px;
$radio-button-diameter: 22px;
$input-border-width: 1px;
$space-between-input-boxes: 20px;
$label-and-error-message-font-size: 12px;

.submit-form-wrapper {
  width: 100%;

  .submit-form-title {
    color: $color-darkest-grey;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 14px;
  }

  .submit-form-subtitle {
    color: $color-dark-grey;
    margin-bottom: 28px;
    width: 100%;
  }

  .submit-form-actual-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    :-webkit-autofill {
      // Delays the autofill background change for a year
      // The !important is required to override the default behavior
      transition: all 1s 31536000s !important;
      transition-property: color, background-color !important;
    }

    .submit-form-row {
      display: flex;
      flex-direction: row;
      margin-top: $space-between-input-boxes;

      &:not(.company-dependent-row) {
        justify-content: space-between;

        .submit-form-text-input-container {
          width: calc(50% - ($space-between-input-boxes / 2));
          margin-right: 0px;

          .submit-form-text-input {
            width: 100%;
          }
        }
      }

      &.company-dependent-row {
        margin-top: 32px;
        flex-wrap: wrap;

        .submit-form-text-input-container {
          // Space around input boxes
          margin-right: $space-between-input-boxes;
          margin-bottom: $textbox-height;
          width: 125px;

          .submit-form-error-message {
            padding-left: calc($textbox-font-size / 2);
          }
        }
      }

      .submit-form-label {
        color: $color-mid-grey;
        font-weight: $font-boldest-font-weight;
        font-size: $label-and-error-message-font-size;
        width: max-content;

        span {
          color: $system-error-red;
        }
      }

      .submit-form-text-input-container {
        display: flex;
        flex-direction: column;

        .submit-form-error-message {
          font-size: $label-and-error-message-font-size;
          color: $system-error-red;
          padding-left: $textbox-font-size;
        }

        .submit-form-text-input {
          resize: none;
          vertical-align: middle;
          border-radius: $default-card-radius;
          margin-top: 8px;
          border: $input-border-width solid $color-mid-grey;
          font-family: $default-font-family;
          height: $textbox-height;
          font-size: $textbox-font-size;
          padding-left: $textbox-font-size;
          color: $color-darkest-grey;

          // Text input outline changes
          outline: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;

          &.error.required {
            border-color: $system-error-red;
          }

          &:focus {
            border-color: $color-darkest-grey;
          }

          // Set placeholder text colour in various browsers
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-mid-grey;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $color-mid-grey;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $color-mid-grey;
          }
        }

        &:last-child .submit-form-text-input {
          margin-right: 0px;
        }
      }

      &.radio-row {
        display: flex;
        flex-direction: column;

        .submit-form-radio-buttons {
          display: flex;
          flex-direction: row;

          .submit-form-radio-button {
            color: $color-mid-grey;
            display: flex;
            align-items: center;
            margin-right: 38px;
            margin-top: 18px;

            label {
              padding-left: 8px;
            }

            input[type="radio"] {
              cursor: pointer;

              // Min and max due to cross-browser styling weirdness
              min-height: $radio-button-diameter;
              min-width: $radio-button-diameter;
              max-height: $radio-button-diameter;
              max-width: $radio-button-diameter;

              // Remove standard styling
              outline: none;
              -moz-appearance: none;
              appearance: none;
              // Must be after appearance for Safari
              -webkit-appearance: none;

              // Recreate unselected styling with desired changes
              border-radius: 50%;
              border: $input-border-width solid $color-mid-grey;
              // Fixes sizing issue on Chrome
              margin: 0px;

              &:checked {
                // Recreate selected styling with desired changes
                display: inline-block;
                background-clip: content-box;
                background-color: $color-purple;
                border-color: $color-purple;
                padding: 4.5px;
                // Firefox doesn't center on 4.5px, but Chrome doesn't center on 5px
                @supports (-moz-appearance: none) {
                  padding: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .submit-form-actual-form .submit-form-row {
      width: 100%;
      // Interpretation of figma intent
      flex-wrap: wrap;
      margin-top: 0px;

      &.mobile-full-width-input {
        .submit-form-text-input-container {
          width: 100%;
        }
      }

      &.company-dependent-row {
        margin-top: 0px;
        margin-bottom: 82px;
        justify-content: start;

        .submit-form-text-input-container {
          width: 154px;

          .submit-form-text-input {
            margin-bottom: 0px;
            width: 100%;
          }
        }
      }

      // Add top margin to labels so it works with wrapping
      .submit-form-label {
        margin-top: $space-between-input-boxes;
      }

      .submit-form-radio-buttons {
        flex-wrap: wrap;

        // Don't add top margin to radio labels
        .submit-form-radio-button .submit-form-label {
          margin-top: 0px;
        }
      }
    }
  }

  // For smaller mobile stylings
  @media (max-width: calc(
    $mobile-breakpoint - $mobile-breakpoint-adjustment
  )) {
    .submit-form-subtitle {
      margin-bottom: 8px;
    }

    .submit-form-actual-form {
      margin: 0px;

      .submit-form-row {
        .submit-form-text-input-container {
          margin-right: 0px;
        }

        &:not(.company-dependent-row) {
          flex-direction: column;

          .submit-form-text-input-container {
            width: 100%;

            .submit-form-text-input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
