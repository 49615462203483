@import "../../styles/constants.scss";

$soc-stamp-mobile-width: 50px;

.wrapper-grid-mobile {
  background-color: $color-white;
  padding: 22px 10%;
  width: 100%;

  .copyright-mobile {
    color: $color-dark-grey;
    font-size: $font-small-font-size;
    text-align: center;
    margin-bottom: 14px;
    line-height: 24px;
  }

  .link-wrapper {
    column-gap: 40px;

    .link-mobile > * {
      font-size: $font-default-font-size;
      font-weight: $font-bold-font-weight;
      color: $color-dark-grey;
      line-height: 24px;
    }

    .aicpa-soc-stamp {
      width: $soc-stamp-mobile-width;
      height: $soc-stamp-mobile-width;
    }
  }
}

.wrapper-grid-mobile.extended {
  @media (max-width: $mobile-breakpoint) {
    padding-bottom: 85px;
  }
}
