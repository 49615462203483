@import "../../styles/constants.scss";

$arrow-icon-explicit-width: 26px;
$arrow-icon-explicit-height: 8px;

.cta-button {
  cursor: pointer;
  font-weight: bold;
  font-family: $default-font-family;

  &.primary {
    border-radius: $button-default-border-radius;
    padding: $default-button-padding;
    background: $color-pink;
    border: 0px;
    color: $color-white;

    &:hover {
      box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.16);
      background: $color-dark-pink;
    }

    &.disabled {
      box-shadow: none;
      background: rgba($color-pink, 0.5);
      cursor: inherit;
    }
  }

  // Secondary button has no background and instead coloured text
  &.secondary {
    background: none;
    border: 0px;
    color: $color-pink;
    display: flex;
    align-items: center;
    padding: $default-button-padding 0px;

    span {
      text-align: start;
    }

    .arrow-icon {
      width: $arrow-icon-explicit-width;
      height: $arrow-icon-explicit-height;
      margin-left: 1em;
    }

    &:hover {
      color: $color-dark-pink;
      .arrow-icon {
        // Matches the $color-dark-pink colour
        filter: brightness(0.85);
      }
    }
  }
}
