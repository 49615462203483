@import "../../../styles/constants.scss";

.terms-page-wrapper {
  background-color: $color-white;
  display: flex;
  flex-flow: column;

  .terms-page-container {
    @include marketing-document-container;

    .terms-page-header {
      padding-bottom: 20px;
    }

    ol {
      counter-reset: integer;
      padding-left: 0;
    }

    ol li {
      list-style: none;
      position: relative;
    }

    ol > li:before {
      counter-increment: integer;
      content: counter(integer) ". ";
      padding-right: 5px;
    }
  }
}
