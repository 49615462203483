@import "../../styles/constants.scss";

.content-banner-text {
  h4 {
    color: $color-darkest-grey;
    font-weight: $font-boldest-font-weight;
  }

  p {
    color: $color-dark-grey;
  }

  .bolded {
    font-weight: $font-boldest-font-weight;
  }

  // mobile stylings
  @media (max-width: $mobile-breakpoint) {
    p {
      padding-bottom: 8px;
    }
  }
}
