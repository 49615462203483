@import "../../../../src/styles/constants.scss";

.not-found-container {
  background: radial-gradient(darken($color-warmer-purple, 3%), $color-black);
  min-height: calc(100vh - $header-height);
  padding: 0px 10px;
  text-align: center;

  @media (max-width: $mobile-breakpoint) {
    min-height: calc(100vh - $mobile-header-height);
  }

  .not-found-graphic-image {
    max-width: 100%;
  }

  .not-found-header {
    font-size: $mobile-h2-font-size;
    font-weight: $font-boldest-font-weight;
    margin-top: 31px;
    margin-bottom: 20px;

    @media (max-width: $mobile-breakpoint) {
      font-size: $font-default-line-height;
    }
  }

  .not-found-message {
    font-size: $font-large-font-size;
  }

  .go-back-button {
    margin-top: 70px;
    padding-bottom: 30px;

    button {
      font-size: $font-large-font-size;
    }
  }

  &.logged {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.not-logged {
    color: $color-white;
  }
}
