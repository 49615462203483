@import "../../styles/constants.scss";

.marketing-drawer-wrapper {
  overflow: hidden;

  .request-demo-button {
    justify-content: center;

    .request-demo-option {
      background-color: $color-pink;
      border-radius: $button-default-border-radius;
      color: $color-white;
      width: 290px;
      text-align: center;
      padding: 8px 0;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .socials {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .social-media-icon {
      margin: 10px 10px;
    }
  }
}
