@import "../../styles/constants.scss";

.docs-content-wrapper {
  @include docs-content-wrapper;

  .docs-content {
    @include integration-doc-content;

    .integration-lifecycle-image {
      padding: 2rem 0rem;
      width: 100%;
    }

    .integration-lifecycle-btns-wrapper {
      padding: 1rem 0rem 2rem;

      .integration-lifecycle-btn {
        background-color: $color-lightest-grey;
        border: 1px solid $color-purple;
        border-radius: 100px;
        cursor: pointer;
        color: $color-purple;
        display: inline-block;
        font-weight: $font-bold-font-weight;
        margin: 10px 5px;
        padding: 12px 32px;

        &:hover {
          background-color: $color-lightest-grey;
        }
      }

      .integration-lifecycle-btn.selected {
        background-color: $color-purple;
        color: $color-white;
      }
    }
  }
}
