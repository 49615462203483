@import "../../styles/constants.scss";

$column-wrapper-width: 47%;
$adjusted-mobile-breakpoint: calc(
  $mobile-breakpoint - $mobile-breakpoint-adjustment
);

.submit-form-banner-wrapper {
  width: 100%;
  height: fit-content;
  justify-content: space-between;

  &.reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .submit-form-banner-image-wrapper {
    width: $column-wrapper-width;
    display: flex;
    align-items: center;

    img {
      width: min(100%, 800px);
      height: auto;
      object-fit: cover;
    }
  }

  .submit-form-banner-form-wrapper {
    width: $column-wrapper-width;
    max-width: 850px;
  }

  // The mobile stylings
  @mixin submit-form-mobile-view {
    margin-left: $mobile-side-padding;
    margin-right: $mobile-side-padding;
    .submit-form-banner-form-wrapper {
      width: 100%;
      max-width: 100%;
    }

    .submit-form-banner-image-wrapper {
      width: 100%;
      position: relative;
      padding-bottom: 25px;

      img {
        width: min(100%, 800px);
        height: auto;
        object-fit: cover;
      }
    }

    // Align image to edge of page
    &.reverse {
      .submit-form-banner-image-wrapper {
        right: -$mobile-side-padding;
      }
    }

    &:not(.reverse) {
      .submit-form-banner-image-wrapper {
        left: -$mobile-side-padding;
      }
    }
  }

  @media (max-width: calc($mobile-breakpoint)) {
    // Delay mobile styling switch if the image is intended to be visible
    &:not(.show-image-mobile) {
      @include submit-form-mobile-view;
    }

    // mimic the default mobile side padding look
    &.show-image-mobile {
      &.reverse {
        margin-left: $mobile-side-padding;
      }

      &:not(.reverse) {
        margin-right: $mobile-side-padding;
      }
    }
  }

  @media (max-width: $adjusted-mobile-breakpoint) {
    @include submit-form-mobile-view;
    margin-left: 0px;
  }
}
