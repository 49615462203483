@import "../../styles/constants.scss";

.footer-navigation-link {
  color: $color-mid-grey;
  font-size: $font-large-font-size;
  font-weight: $font-extra-bold-font-weight;
  transition: all ease 0.4s;
  line-height: $font-default-line-height;

  &:hover {
    color: $color-white;
  }
}
