@import "../../styles/constants.scss";

$default-header-line-height: 1.25em;
$default-header-letter-spacing: 0.5px;
$small-letter-spacing: 0px;

// desktop and general styling
.marketing-page-wrapper {
  position: relative;
  min-height: 100vh;

  width: 100%;
  height: 100%;
  font-family: $default-font-family;
  // Matches the header color for weird, quick scrolling up behaviour
  // No padding needed since position is relative
  background: $color-darkest-grey;

  // Default text styling
  // Check constants.scss for other versions/mixins
  font-size: $font-large-font-size;
  line-height: 1.75em;
  letter-spacing: $small-letter-spacing;

  // explicitly assign the default font size to affect button text as well
  button {
    font-size: $font-large-font-size;
  }

  // Headers
  h1 {
    font-size: 48px;
    line-height: $default-header-line-height;
    letter-spacing: $default-header-letter-spacing;
  }

  h2 {
    font-size: $font-xxlarge-font-size;
    line-height: $default-header-line-height;
    letter-spacing: $default-header-letter-spacing;
  }

  h3 {
    font-size: $mobile-h2-font-size;
    line-height: $default-header-line-height;
    letter-spacing: $default-header-letter-spacing;
  }

  h4 {
    font-size: $font-xmlarge-font-size;
    line-height: 1.45em;
    letter-spacing: $small-letter-spacing;
  }

  h5 {
    font-size: $font-large-font-size;
    line-height: 1.75em;
    letter-spacing: $small-letter-spacing;
  }

  .marketing-page-view {
    background-color: $color-darkest-grey;
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
    width: 100%;
  }
}

// mobile styling differences from default desktop
@media (max-width: $mobile-breakpoint) {
  .marketing-page-wrapper {
    // For the sticky/fixed mobile header
    padding-top: $mobile-header-height;

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: $mobile-h2-font-size;
    }

    h3 {
      font-size: 28px;
      line-height: 1.45em;
      letter-spacing: $small-letter-spacing;
    }

    h4 {
      font-size: $font-xlarge-font-size;
      line-height: 1.5em;
    }

    h5 {
      font-size: $font-large-font-size;
    }

    .marketing-page-view {
      background-color: $color-white;
      min-height: calc(
        100vh - #{$mobile-header-height} - #{$mobile-footer-height}
      );
    }
  }
}
