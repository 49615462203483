@import "../../styles/constants.scss";

$max-image-width: 663px;
$top-banner-h1-transition-styling-max-width: 1045px;

.top-banner {
  color: $color-white;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .top-banner-text {
    h1 {
      margin-bottom: 0px;

      // Resize h1 font within certain ranges to be aesthetically pleasing
      @media (min-width: $mobile-breakpoint) and (max-width: $top-banner-h1-transition-styling-max-width) {
        font-size: $mobile-h2-font-size;
      }
    }

    p {
      color: $color-lightest-purple;
      font-weight: $font-boldest-font-weight;
    }
  }

  .banner-image {
    height: 100%;
    margin-left: 5%;

    // Offset to account for shadow/opaque bottom part of the top banner image
    // So the main image itself looks more centered
    &.offset-shadow {
      position: relative;
      bottom: -30px;
    }

    // Keep the image from growing too large on super wide or thin screens
    @media (min-width: 1460px), (max-width: $mobile-breakpoint) {
      width: min($max-image-width, 100%);
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  // mobile styling differences from default desktop
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;

    .top-banner-text {
      margin: 0px 30px;
    }

    .banner-image {
      margin-left: 0px;
      margin-top: $mobile-top-bottom-padding;

      display: flex;
      align-self: center;

      &.align-right {
        align-self: flex-end;
        // Make smaller to visually match better
        width: min($max-image-width - 150px, 100%);
      }
    }
  }
}
