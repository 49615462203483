@import "../../styles/constants.scss";

$h1-top-padding: 32px;
$hover-background-color: rgba(210, 206, 216, 0.6);

.docs-navigation {
  background-color: $color-white;
  height: 100%;
  padding-top: calc(#{$docs-top-padding} + #{$h1-top-padding});

  .tab-option {
    border-radius: $default-card-radius;
    cursor: pointer;
    margin: 8px 0px;
    padding: 8px $default-button-padding;
    transition: background-color 0.5s;
    width: calc(178px + 2 * 16px);

    &:hover {
      background-color: $hover-background-color;
      transition: background-color 0.5s;
    }
  }

  .tab-option.selected {
    cursor: auto;
    background-color: $hover-background-color;
  }
}
