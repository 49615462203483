@import "../../styles/constants.scss";

$logo-side-padding: 3%;
$logo-bottom-padding: 2%;

.partner-logos-container {
  color: $color-white;

  .title {
    text-align: center;
  }

  .partner-logos {
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // Wrap row so the logos don't have to shrink
    flex-wrap: wrap;

    .partner-logo {
      display: inline-block;
      padding: 0% $logo-side-padding $logo-bottom-padding $logo-side-padding;
      vertical-align: middle;

      &.partner-logo-desktop-spacer {
        pointer-events: none;
        visibility: hidden;
        padding-bottom: 0px;

        @media screen and (max-width: calc($mobile-breakpoint - $mobile-breakpoint-adjustment)) {
          display: none;
        }
      }
    }
  }
}
