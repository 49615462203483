@import "../../styles/constants.scss";

.testimonial-banner-container {
  width: 100%;
  height: fit-content;

  h2 {
    display: flex;
    justify-content: center;
    font-weight: $font-boldest-font-weight;
    color: $color-white;
  }

  .testimonial-as-seen-in-wrapper {
    background-color: $color-purple;
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
