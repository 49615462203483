@import "../../styles/constants.scss";

.home-top-banner {
  display: grid;
  width: 100%;

  .marketing-home-banner-top-banner {
    z-index: 1;
    grid-column: 1;
    grid-row: 1;
    height: fit-content;
  }

  img {
    width: 100%;
    grid-column: 1;
    grid-row: 1;
  }

  .wave-decorative-image {
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
    align-self: end;
    // Truncate instead of resize on window width change
    overflow-x: hidden;

    img {
      min-width: 1400px;
    }
  }

  .marketing-home-banner-background-images {
    height: 100%;
    overflow-y: hidden;
    width: 100%;
    grid-column: 1;
    grid-row: 1;
    display: grid;

    .marketing-home-banner-background-image {
      height: 100%;
      width: 100%;
      grid-column: 1;
      grid-row: 1;

      &.fade {
        @keyframes fadebackground {
          from {
            opacity: 100%;
          }

          to {
            opacity: 0%;
          }
        }
      }
    }
  }
}

// mobile styling differences from default desktop
@media (max-width: $mobile-breakpoint) {
  // Truncate animating background on mobile
  .home-top-banner .marketing-home-banner-background-images {
    overflow: hidden;

    .marketing-home-banner-background-image {
      min-width: $mobile-breakpoint;
    }
  }
}
