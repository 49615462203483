@import "../../styles/constants.scss";

.navigation-wrapper {
  justify-content: center;
  touch-action: none;

  .navigation-brand {
    white-space: nowrap;

    .beatdapp-icon {
      width: 40px;
      height: 39px;
      vertical-align: middle;

      @media screen and (max-width: 300px) {
        display: none;
      }
    }

    .beatdapp-text {
      margin-left: 0.2rem;
      width: 106px;
      height: 20px;
      vertical-align: middle;
    }
  }

  .navigation-buttons {
    white-space: nowrap;

    .navigation-button {
      display: inline-block;

      .portal-link {
        color: #a6a2ab;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        margin-right: 30px;
        transition: color 0.5s ease;
        &:hover {
          color: #fff;
        }
      }

      .request-demo-button {
        @include request-demo-button;
        font-weight: $font-extra-bold-font-weight;
      }
    }
  }

  .drawer-menu-icon {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    height: 30px;
    width: 30px;
    margin-top: 5px;
    margin-right: 20px;
    transition: all ease 0.2s;
    cursor: pointer;
  }
}
