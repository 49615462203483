@import "../../styles/constants.scss";

$minimum-margin-between-text-and-image: min(70px, 5%);
$title-centering-offset-amount: calc($desktop-side-padding / 2);

.content-banner-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .content-banner-title {
    color: $color-purple;
    width: fit-content;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    text-align: center;
    // Center title horizontally on screen, ignoring the padding
    position: relative;
    left: calc(-1 * #{$title-centering-offset-amount});

    &.content-banner-subtitle {
      font-size: larger;
      padding-top: 10px;
    }
  }

  .content-banner-columns-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 75px;
    // Keep image aligned to the edge of the screen
    justify-content: space-between;
    align-items: stretch;

    .content-banner-text-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .content-banner-image-wrapper {
      width: 50%;
      margin-left: $minimum-margin-between-text-and-image;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content-banner-image {
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          // Anything other than default 'inline'
          display: block;
        }

        // Keep specified images from the edge of the screen
        &.offset {
          margin-right: 25%;
          margin-bottom: 20%;
        }

        // used only in conjunction with ignore-side-padding
        &.offset-bottom {
          margin-bottom: 20%;
        }
      }
    }

    // For the hidden mobile text box
    .mobile-only {
      display: none;
    }
  }

  &.left {
    .content-banner-title {
      left: $title-centering-offset-amount;
    }

    .content-banner-columns-wrapper .content-banner-image-wrapper {
      margin-left: 0px;
      margin-right: $minimum-margin-between-text-and-image;

      .content-banner-image.offset {
        margin-left: $minimum-margin-between-text-and-image;
      }
    }
  }
}

@media (max-width: calc($mobile-breakpoint + $mobile-breakpoint-adjustment)) {
  .content-banner-wrapper {
    .content-banner-columns-wrapper {
      .content-banner-image-wrapper {
        // Fix awkward whitespace gap with multiple images
        justify-content: space-around;
      }
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .content-banner-wrapper {
    .content-banner-title {
      position: inherit;
      text-align: left;
      // Center horizontally
      padding-right: $mobile-side-padding;
    }

    // General spacing
    h4 {
      margin-top: $mobile-top-bottom-padding;
      margin-bottom: $mobile-top-bottom-padding;
    }

    p {
      margin: 0px;
    }

    .content-banner-columns-wrapper {
      flex-direction: column-reverse;
      margin-top: 0px;

      .content-banner-text-wrapper {
        // Fill full width of screen
        // Since flex-direction is now column
        width: 100%;
        margin-right: $mobile-side-padding;
      }

      // if desktop only -> hide
      .desktop-only {
        display: none;
      }

      // For the hidden mobile-only text box
      .mobile-only {
        display: block;
        margin-right: $mobile-side-padding;
      }

      .content-banner-image-wrapper {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: $mobile-top-bottom-padding;
        width: 100%;

        .content-banner-image {
          &.offset {
            margin-right: $mobile-side-padding;
            padding: 0px 5%;
            margin-bottom: 0px;
          }

          // If specified in image prop
          // Have image fill the full width of the screen
          &.ignore-side-padding img {
            position: relative;
            left: -$mobile-side-padding;
            width: calc(100% + $mobile-side-padding);
          }
        }

        // Fix spacing for non-default right images
        &.mobile-right {
          // Has to be padding instead of margin for webp images
          padding-right: $mobile-side-padding;
        }
      }
    }

    &.right .content-banner-columns-wrapper {
      flex-direction: column;
    }
  }
}
