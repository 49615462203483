@import "../../styles/constants.scss";

$card-padding: 24px;
$image-size: 64px;
$testimonial-title-height: 95px;

.testimonial-card-container {
  background-color: $color-white;
  border-radius: $button-default-border-radius;
  color: $color-darkest-grey;
  min-height: 389px;
  padding: $card-padding;
  position: relative;
  width: 315px;

  .testimonial-card-header {
    .testimonial-image {
      display: inline-block;
      height: 100%;
      vertical-align: top;

      img {
        border-radius: 50%;
        height: $image-size;
        width: $image-size;
      }
    }

    .testimonial-person {
      display: inline-block;
      padding-left: 5%;
      width: calc(100% - #{$image-size});

      .testimonial-name {
        font-weight: $font-boldest-font-weight;
        font-size: $font-xlarge-font-size;
        line-height: 30px;
      }

      .testimonial-title {
        font-size: $font-large-font-size;
        // Ensures the text underneath all start at the same height
        height: $testimonial-title-height;
      }
    }
  }

  .testimonial-quote {
    font-size: $font-xlarge-font-size;
    line-height: 36px;
  }
}
