/* App colors */
$color-purple: #772edd;
$color-light-purple: #8d52e0;
$color-lightest-purple: #e4d5f8;
$color-warmer-purple: #8600d6;

$color-pink: #ff2384;
$color-light-pink: #d65c95;
$color-dark-pink: #d3005d;
$color-light-pink-trans: rgba(255, 35, 132, 0.5);

/* Various greys, sorted by actual brightness */
$color-black: #000000;
$color-darkest-grey: #292332;
$color-darker-grey: #4a4a4a;
$color-dark-grey: #5f5b65;
$color-mid-grey: #a6a2ab;
$color-light-grey: #afafaf;
$color-lighter-grey: #f8f8f8;
$color-lightest-grey: #faf9fc;
$color-white: #ffffff;

/* Red and green indicating errors or success */
$system-error-red: #f04f59;
$system-success-green: #46b958;

/* App font styles */
$font-small-font-size: 12px;
$font-default-font-size: 14px;
$font-large-font-size: 16px;
$font-xlarge-font-size: 20px;
$font-xmlarge-font-size: 24px;
$font-xxlarge-font-size: 40px;

$font-default-font-weight: 400;
$font-bold-font-weight: 500;
$font-extra-bold-font-weight: 600;
$font-boldest-font-weight: 700;

$font-default-line-height: 28px;

$mobile-h2-font-size: 32px;

/* App misc constants */
$default-card-radius: 8px;
$mobile-breakpoint: 899px;
// The adjustment can be added to/subtracted from the mobile breakpoint
// For transitional stylings between 'mobile' and 'desktop' views
$mobile-breakpoint-adjustment: 300px;
$default-font-family: Roboto;

$header-height: 92px;
$mobile-header-height: 84px;
$desktop-side-padding: 208px;
$mobile-side-padding: 16px;
$mobile-top-bottom-padding: 40px;
$default-button-padding: 16px;

$footer-height: 315px;
$mobile-footer-height: 110px;

// docs related pages
$docs-top-padding: 2%;

@mixin docs-content-wrapper {
  background-color: $color-lightest-grey;
  padding: $docs-top-padding 10% 5% 5%;

  .docs-content {
    max-width: 1000px;

    div {
      color: $color-dark-grey;
      margin: 24px 0px;
    }

    h1 {
      color: $color-darkest-grey;
    }

    h4 {
      color: $color-purple;
    }

    a {
      color: $color-purple;
      text-decoration: none;
    }
  }
}

@mixin integration-doc-content {
  .integration-doc-header {
    color: $color-darkest-grey;
    margin: 0px;
  }

  .integration-doc-section {
    margin: 0px 0px 32px;

    .integration-doc-bullet {
      margin-left: 24px;
    }
  }
}

/* Button styles */
$button-default-border-radius: 8px;

@mixin request-demo-button {
  border-radius: $button-default-border-radius;
  font-weight: $font-bold-font-weight;
  font-size: $font-large-font-size;
  text-transform: none;
  padding: 8px $default-button-padding;

  &:hover {
    background-color: $color-dark-pink;
  }

  &:active {
    background-color: $color-light-pink-trans;
  }
}

/* marketing document styling */
$doc-title-spacing: 84px;

@mixin marketing-document-container {
  color: $color-dark-grey;
  margin: $doc-title-spacing 20% 0;
  font-size: $font-large-font-size;
  line-height: $font-default-line-height;

  @media screen and (max-width: $mobile-breakpoint) {
    margin: $doc-title-spacing 15% 0;
  }

  @media screen and (max-width: calc(
    $mobile-breakpoint - $mobile-breakpoint-adjustment
  )) {
    margin: $doc-title-spacing 10% 0;
  }

  h1 {
    color: $color-darkest-grey;
    font-size: $font-xxlarge-font-size;
    line-height: normal;
    margin: 0 0 18px;
    text-align: left;
  }

  h4 {
    color: $color-darkest-grey;
    font-size: $font-large-font-size;
    margin: 0;
  }

  a {
    color: $color-purple;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0 0 35px;
    padding: 0px 0px;
  }
}

/* Export variables needed in JS code */
:export {
  mobileBreakpoint: $mobile-breakpoint;
  colorDarkestGrey: $color-darkest-grey;
  colorMidGrey: $color-mid-grey;
  fontDefaultLineHeight: $font-default-line-height;
  fontBoldFontWeight: $font-bold-font-weight;
  fontExtraBoldFontWeight: $font-extra-bold-font-weight;
  fontLargeFontSize: $font-large-font-size;
  headerHeight: $header-height;
  mobileHeaderHeight: $mobile-header-height;
  defaultButtonPadding: $default-button-padding;
}
