@import "../../styles/constants.scss";

.loader {
  margin: auto;
  border: 0.5rem solid $color-lightest-purple;
  border-radius: 50%;
  border-top: 0.5rem solid $color-purple;
  width: 3.5rem;
  height: 3.5rem;
  -webkit-animation: spinner 4s linear infinite;
  animation: spinner 4s linear infinite;

  @-webkit-keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
