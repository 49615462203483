@import "../../../styles/constants.scss";
$looker-background-grey: #f8fcfc;
$looker-text-black: #3a4245;
$default-back-button-top: 90px;

.reporting-dash-page {
  width: 100vw;
  height: 100vh;
  min-height: fit-content;

  .reporting-dash-banner {
    background: linear-gradient(
      to right top,
      $color-pink 0%,
      $color-warmer-purple 60%,
      $color-purple
    );
    height: calc(100% - $header-height);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .reporting-dash-loading-animation {
      position: absolute;
    }

    .reporting-back-button {
      color: $looker-text-black;
      text-transform: none;
      background-color: $looker-background-grey;
      border-radius: 100%;

      // Strict sizing to override the default button styles
      max-width: 24px;
      max-height: 24px;
      min-width: 24px;
      min-height: 24px;
      padding: 20px;

      // position at upper left corner of iframe
      position: absolute;
      top: $default-back-button-top;
      left: 26px;
      visibility: visible;
      opacity: 1;
      transition: visibility 0s 0.5s, opacity 0.5s, top 0.5s linear;

      &.disabled {
        cursor: default;
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
      }

      &:hover {
        background-color: lighten($looker-background-grey, 50%);
        cursor: pointer;
      }

      &.lowered {
        top: calc($default-back-button-top + 75px);
      }

      @media screen and (min-width: 1204px) {
        // Move button left to match Looker Dash default stylings
        left: 66px;
      }
    }

    .reporting-dash-iframe {
      // Make dash flickering less apparent
      background-color: $looker-background-grey;
    }
  }

  // Adjust for mobile header/page differences
  @media screen and (max-width: $mobile-breakpoint) {
    padding-top: $mobile-header-height;
    .reporting-dash-banner {
      height: 100%;
    }

    // Hide month label on very small screens
    @media screen and (max-width: calc($mobile-breakpoint - $mobile-breakpoint-adjustment)) {
      .reporting-dash-select-filter .dropdown-label {
        display: none;
      }
    }
  }
}
