@import "../../styles/constants.scss";

$soc-stamp-desktop-width: 65px;

.desktop-footer-wrapper {
  background-color: $color-darkest-grey;
  padding: 0 10%;

  .grid-top-half {
    margin-top: 48px;

    .navigation-brand {
      white-space: nowrap;

      .beatdapp-icon {
        width: 48px;
        vertical-align: middle;

        @media screen and (max-width: 300px) {
          display: none;
        }
      }

      .beatdapp-text {
        margin-left: 8px;
        width: 130px;
        vertical-align: middle;
      }
    }

    .socials {
      margin-top: 25px;

      .social-icon-wrapper {
        display: inline-block;
        margin-right: 20px;
      }
    }

    .links {
      display: flex;
      flex-wrap: nowrap;
      padding: 0px 10px 0px 20px;

      .navigation-items {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding-right: 20px;

        .navigation-item {
          padding-bottom: 7px;

          &:last-child {
            padding-bottom: 0px;
          }
        }
      }
    }

    .aicpa-soc-stamp-wrapper {
      display: flex;
      justify-content: center;

      .aicpa-soc-stamp {
        width: $soc-stamp-desktop-width;
        height: $soc-stamp-desktop-width;
      }
    }
  }

  .copyright {
    color: $color-lightest-grey;
    font-size: $font-default-font-size;
    line-height: $font-default-line-height;
    margin-top: 25px;
    margin-bottom: 40px;
  }
}
